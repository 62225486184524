<template lang="">
  <div class="pokemon-container">
    <img :src="urlImg" class="hidden-pokemon" alt="pokemon" />
    <img v-if="showPokemon" :src="urlImg" class="fade-in" alt="pokemon" />
  </div>
</template>
<script>
import { computed } from "vue";
export default {
  props: {
    pokemonId: {
      type: Number,
      required: true,
    },
    showPokemon: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const urlImg = computed(() => {
      return "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/home/" +
        props.pokemonId +
        ".png"
    });


    return { urlImg }
  }
};
</script>
<style scoped>
/* Pokemon Picture */
.pokemon-container {
  padding: 10px;
  height: 180px;
}
img {
  height: 180px;
  position: absolute;
  margin-left: -5vw;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.hidden-pokemon {
  filter: brightness(0);
}
@media only screen and (max-width: 600px) {
  .pokemon-container {
    padding: 10px;
    height: 120px;
  }
  img {
    height: 120px;
    position: absolute;
    margin-left: -17vw;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
  }
}
</style>
