<template lang="">
  <div class="comodinDiv">
    <img src="../assets/50per.png" class="comodinImg" />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.comodinImg {
  heigh: 50px;
  width: 50px;
}
</style>
