
<template lang="">

      <div v-if="showRank">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
            <div class="modal-header">
              <h5 class="modal-title">Ranking</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeClasificacion">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
              <div class="modal-body">
                <div>
                    <table class="table table-bordered table-responsive-sm">
                        <thead class="thead">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Points</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr v-for=" ({id,name, points,fecha},index)  in users" :key="id">
                            <td :id="index+1">{{index+1}}</td>
                            <td id="fffff">{{ name }}</td>
                            <td>{{ points }}</td>
                            <td>{{fecha}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                </div>  
            </div>
          </div>
        </div>
      </div>
<div class="container">
  <div class="container-pokemon">
    <h1 class="mTitle">WHO'S THAT POKÉMON?</h1>
    <img
      v-if="!pokemon"
      src="../assets/loading-pokemon2.gif"
      class="loading-poke"
    />
    <div v-else>
      <div class="row">
        <div class="col-xl-4"></div>
        <div class="col-xl-4 col-12"><PokemonPicture :pokemonId="pokemon.id" :showPokemon="showPokemon" v-if="!showRanking"/></div>
        <div class="col-xl-4"></div>
      </div>
      <div class="row">
        <div class="col-xl-4"></div>
        <div class="col-xl-4 col-12"><PokemonOption :pokemons="pokemonArr" @selection-pokemon="checkAnswer" :vidas="vidas" :Aciertos="aciertos" :class="{display:none}" v-if="!showRanking"/></div>
        <div class="col-xl-4"></div>
      </div>
      <div v-if="showRanking">
        <PokemonRanking :points="aciertos"/>
      </div>
      <template v-if="showAnswer">
        <h2 class="msg">{{ message.toUpperCase() }}</h2>
        <h2 class="msg">{{ messageReset.toUpperCase() }}</h2>
        <button @click="nextQuestion" v-if="showNextQuestion" class="buttonQuestionGame">NEXT QUESTION</button>
        <button @click="newGame" v-else class="buttonQuestionGame">NEW GAME</button>
      </template>
      <div class="row heightRow">
        <!-- <button class="trofeo" @click="openClasificacion"><img src="../assets/trofeo.png"></button> -->
        <!-- <img src="../assets/50per.png" class="mitad" @click="percent" :disabled="disabled == 1"> -->
        <div class="col-xl-4 col-0"></div>
        <div class="col-2 col-xl-1" @click="percent">
          <button  :disabled="disabled == 1" class="button-percent"><img src="../assets/50per.png" class="percent"></button>
          <!-- <button @click="percent" :disabled="disabled == 1" class="button-percent"><img src="../assets/50per.png" class="percent"></button> -->
        </div>
        <div class="col-xl-2 col-6"></div>
        <img src="../assets/trofeo.png" class="trofeo col-xl-1 col-3" @click="openClasificacion">
      </div>
        <!-- <div><i class="fas fa-trophy trofeo" @click="openClasificacion"></i></div> -->
    </div>
    </div>
  </div>
  <footer class="text-center text-lg-start">
  <!-- Copyright -->
  <div class="text-center pb-2 mt-2">
    <!-- © 2020 Copyright: -->
    <a class="text-white" href="https://www.linkedin.com/in/%C3%A1lvaro-roldan-alcala-113715170/">Álvaro Roldán Alcalá ©</a>
  </div>
  <!-- Copyright -->
</footer>
</template>
<script>

import PokemonOption from "@/components/PokemonOption.vue";
import PokemonPicture from "@/components/PokemonPicture.vue";
import getPokemonOptions from "../helpers/getPokemonOptions.js";
import PokemonComodin from '@/components/PokemonComodin.vue';
import PokemonRanking from "@/components/PokemonRanking.vue";
import { loadUsers } from '@/main'
import { ref, onMounted } from "vue";
export default {
  components: { PokemonOption, PokemonPicture, PokemonComodin, PokemonRanking },
  // setup() {
  //   const pokemonArr = ref([]);
  //   const pokemon = ref(null);
  //   const showPokemon = ref(false);
  //   const showAnswer = ref(false);
  //   const message = ref("");
  //   const messageReset = ref("");
  //   const vidas = ref(3);
  //   const showNextQuestion = ref(true);
  //   const aciertos = ref(0);
  //   const isDisabledAnswer = ref(false);

  //   const mixPokemonArr = async () => {
  //     pokemonArr.value = await getPokemonOptions();
  //     const rnInt = Math.floor(Math.random() * 4);
  //     pokemon.value = pokemonArr[rnInt];
  //   };
  //   const checkAnswer = (selectedId) => {
  //     if (pokemon.id == selectedId) {
  //       message.value = "¡Has acertado! El pokémon es " + pokemon.name;
  //       aciertos.value++
  //       if (aciertos.value == 10) {
  //         messageReset.value = "¡Has ganado!";
  //         showNextQuestion.value = false
  //       }

  //     } else {
  //       message.value = "¡Has fallado! El pokémon es " + pokemon.name;
  //       vidas.value--
  //     }
  //     if (vidas.value == 0) {
  //       messageReset.value = "¡Te has quedado sin vidas!";
  //       showNextQuestion.value = false

  //     }

  //     showPokemon.value = true;
  //     showAnswer.value = true;
  //     isDisabledAnswer.value = true
  //   };
  //   const newGame = () => {
  //     pokemonArr.value = [];
  //     pokemon.value = null;
  //     showPokemon.value = false;
  //     showAnswer.value = false;
  //     mixPokemonArr();
  //     vidas.value = 3
  //     aciertos.value = 0
  //   };
  //   const nextQuestion = () => {
  //     pokemonArr.value = [];
  //     pokemon.value = null;
  //     showPokemon.value = false;
  //     showAnswer.value = false;
  //     mixPokemonArr();
  //   };
  //   onMounted(() => {
  //     mixPokemonArr();
  //   });
  //   return {
  //     pokemonArr,
  //     pokemon,
  //     showPokemon,
  //     showAnswer,
  //     message,
  //     messageReset,
  //     vidas,
  //     showNextQuestion,
  //     aciertos,
  //     isDisabledAnswer,
  //     mixPokemonArr,
  //     checkAnswer,
  //     newGame,
  //     nextQuestion
  //   }
  // },
  data() {
    const users = loadUsers()
    return {
      pokemonArr: [],
      pokemon: null,
      showPokemon: false,
      showAnswer: false,
      message: "",
      messageReset: "",
      vidas: 3,
      showNextQuestion: true,
      aciertos: 0,
      isDisabledAnswer: false,
      showRanking: false,
      showRank: false,
      users,
      id: '',
      disabled: 0,
      recuperarComodin: 0,
      isComodinOn: true
    };
  },
  methods: {
    async mixPokemonArr() {
      this.pokemonArr = await getPokemonOptions();
      const rnInt = Math.floor(Math.random() * 4);
      this.pokemon = this.pokemonArr[rnInt];
    },
    percent() {
      if (this.disabled != 1) {

        for (let index = 0; index < this.pokemonArr.length; index++) {
          if (this.pokemonArr[index].id == this.pokemon.id) {
            this.pokemonArr.splice(index, 1)
          }

        }
        for (let index = 0; index < 2; index++) {
          const nombreAleatorio = Math.floor(Math.random() * 2);
          if (this.pokemonArr[nombreAleatorio].id != this.pokemon.id) {
            this.pokemonArr.splice(nombreAleatorio, 1)
          }
        }
        const shakeNombre = Math.floor(Math.random() * 2);
        this.pokemonArr.splice(shakeNombre, 0, this.pokemon)
        this.disabled = 1
        this.recuperarComodin = 0
        this.isComodinOn = false
      } else {
        this.disabledPercent()
      }

    },
    checkAnswer(selectedId) {
      if (this.isDisabledAnswer) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'You can keep playing in the next question!',
        })
        // this.isDisabledAnswer = false
      } else {
        if (this.pokemon.id == selectedId) {
          this.message = "You got it! It's " + this.pokemon.name;
          this.aciertos++
          this.recuperarComodin++
          if (this.recuperarComodin % 15 == 0 && this.aciertos != 0 && this.isComodinOn == false) {
            this.disabled = 0
            this.isComodinOn = true
            this.$swal({
              icon: 'info',
              title: 'Comodín',
              text: 'Look! The 50% lifeline is ready!',
            })

          }
        } else {
          this.message = "You failed! It's  " + this.pokemon.name;
          this.vidas--
        }
        if (this.vidas == 0) {
          this.messageReset = "Puntos totales: " + this.aciertos;
          this.showNextQuestion = false
          this.showRanking = true

        }

        this.showPokemon = true;
        this.showAnswer = true;
        this.isDisabledAnswer = true
      }
    },
    newGame() {
      this.pokemonArr = [];
      this.pokemon = null;
      this.showPokemon = false;
      this.showAnswer = false;
      this.mixPokemonArr();
      this.vidas = 3
      this.aciertos = 0
      this.messageReset = ""
      this.showNextQuestion = true
      this.isDisabledAnswer = false
      this.showRanking = false
      this.showRank = false
      this.disabled = 0
    },
    nextQuestion() {
      this.pokemonArr = [];
      this.pokemon = null;
      this.showPokemon = false;
      this.showAnswer = false;
      this.mixPokemonArr();
      this.isDisabledAnswer = false
      if (this.aciertos % 10 == 0) {
        this.disabled = 0
      }
    },
    openClasificacion() {
      this.showRank = true
    },
    closeClasificacion() {
      this.showRank = false
    },
    disabledPercent() {

      var contadorAciertos = 0
      contadorAciertos = 10 - this.recuperarComodin
      if (this.disabled == "1") {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'You only need ' + contadorAciertos + " correct answers to get it back, keep going!",
        })
      }
    }


  },

  mounted() {
    this.mixPokemonArr();
    this.id = this._uid
  },
};
</script>
<style>
.loading-poke {
  width: 350;
  height: 250px;
}
.container-pokemon {
  /* width: 800px; */
  background-color: white;
  /* font-family: Pokemon; */
  /* font-family: "Pokemon Solid", sans-serif; */
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  /*font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;*/
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: bold;
  color: black;
  letter-spacing: 0.1em;
  border: 3px solid black;
}
.buttonQuestionGame {
  cursor: pointer;
  margin-right: 3px;
  height: 50px;
  margin-bottom: 10px;
  background-image: linear-gradient(
    #ee1515,
    #f00000,
    #222224,
    #222224,
    #f0f0f0,
    #f0f0f0
  );
  color: white;
  font-weight: bold;
  border: 3px solid black;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 7px -1px #000000;
  box-shadow: 4px 4px 7px -1px #000000;
}
.buttonQuestionGame:hover {
  -webkit-box-shadow: 2px 4px 7px -1px #000000;
  box-shadow: 2px 4px 7px -1px #000000;
}
.mTitle {
  /* font-family: Pokemon; */
  /* font-family: "pokemon-font"; */
  padding: 15px;
  /* color: #ffcb04; */
  /* text-shadow: 3px 3px 0 #39569e, -3px -3px 0 #39569e, 4px -1px 0 #39569e,
    -1px 4px 0 #39569e, 1px 1px 0 #39569e; */
  color: #ffcb05;
  /* color: #444444; */
  background: #ffffff;
  text-shadow: 1px 0px 1px #2a75bb, 0px 1px 1px #2a75bb, 2px 1px 1px #2a75bb,
    1px 2px 1px #2a75bb, 3px 2px 1px #2a75bb, 2px 3px 1px #2a75bb,
    4px 3px 1px #2a75bb, 3px 4px 1px #2a75bb, 5px 4px 1px #2a75bb,
    4px 5px 1px #3c5aa6, 6px 5px 1px #3c5aa6, 5px 6px 1px #3c5aa6,
    7px 6px 1px #3c5aa6;
}
.msg {
  font-size: 20px;
}
.trofeo {
  cursor: pointer;
  /* margin-left: 280px; */
  margin-bottom: 8px;
  height: 60px;
  width: 40px;
  font-size: 25px;
}

/*********** modal*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.thead {
  background-color: #ee1515;
}
footer {
  display: none;
}
[id="1"] {
  background-color: #09a751;
  border: 2px #09a751 solid;
}
/* [id="2"] {
  background-color: #09a751;
}
[id="3"] {
  background-color: #09a751;
} */
.swal2-styled.swal2-confirm {
  background-color: #f27474 !important;
}
.percent {
  margin-bottom: 8px;
  height: 50px;
  width: 50px;
  font-size: 25px;
}
.button-percent {
  background-color: white;
  border: none;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
@media only screen and (max-width: 600px) {
  .mTitle {
    font-size: 20px;
    padding: 15px;
    /* text-shadow: 1px 0px 1px #2a75bb, 0px 1px 1px #2a75bb, 2px 1px 1px #2a75bb,
      1px 2px 1px #2a75bb, 3px 2px 1px #2a75bb, 2px 3px 1px #2a75bb,
      4px 3px 1px #2a75bb, 3px 4px 1px #2a75bb, 5px 4px 1px #2a75bb,
      4px 5px 1px #3c5aa6, 6px 5px 1px #3c5aa6, 5px 6px 1px #3c5aa6,
      7px 6px 1px #3c5aa6; */
    text-shadow: 1px 0px 1px #2a75bb, 0px 1px 1px #2a75bb, 2px 1px 1px #2a75bb,
      1px 2px 1px #2a75bb, 3px 2px 1px #2a75bb, 2px 3px 1px #2a75bb,
      3px 2px 1px #2a75bb, 3px 4px 1px #2a75bb, 2px 3px 1px #2a75bb,
      2px 3px 1px #3c5aa6, 3px 2px 1px #3c5aa6, 2px 2px 1px #3c5aa6,
      3px 2px 1px #3c5aa6;
  }
  .msg {
    font-size: 15px;
  }
  .buttonQuestionGame {
    font-size: 15px;
    height: 40px;
    margin-bottom: 10px;
    background-image: linear-gradient(
      #ee1515,
      #f00000,
      #222224,
      #222224,
      #f0f0f0,
      #f0f0f0
    );
    color: white;
    font-weight: bold;
    border: 3px solid black;
    border-radius: 10px;
    -webkit-box-shadow: 4px 4px 7px -1px #000000;
    box-shadow: 4px 4px 7px -1px #000000;
  }
  .modal-container {
    width: 300px;
    padding-bottom: 1px;
  }
  .modal-body {
    margin-top: 20px;
    padding: 0px;
  }
  .table {
    height: 470px;
    overflow-y: scroll;
  }
  .trofeo {
    height: 45px;
    width: 35px;
    margin-left: 18px;
  }
  .percent {
    height: 40px;
    width: 40px;
    margin-left: 10px;
  }
  footer {
    background-color: black;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
</style>
