import { reactive } from 'vue';
<template lang="">
  <div data-="" class="options-container">
    <ul>
      <li
        v-for="pokemon in pokemons"
        :key="pokemon.id"
        @click="$emit('selectionPokemon', pokemon.id)"
        class="btn btn-outline-danger"
      ><img src="../assets/pokemon2.png" class="imagenLi"/>
        {{ pokemon.name.toUpperCase() }}
      </li>
    </ul>
  </div>
  <div class="divImgVidas">
    <img v-for="vida in vidas" src="../assets/pokemon2.png" class="pokeImg" />
  </div>
  <div class="divAciertos">POINTS:<span>{{ Aciertos}}</span></div>
</template>
<script>
export default {
  props: {
    pokemons: {
      type: Array,
      required: true,
    },
    vidas: {
      type: Number,
      required: true,
    },
    Aciertos: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
/* Pokemon Options */
ul {
  margin-right: 40px;
  margin-top: 20px;
  list-style-type: none;
}
li {
  height: 40px;
  padding-top: 10px;
  font-size: 15px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 10px;
  width: 250px;
}
.options-container {
  display: flex;
  justify-content: center;
}
.pokeImg {
  height: 25px;
  width: 25px;
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  margin-left: 2px;
}
/* .divImgVidas {
  /* margin-left: 40px; 
} */
.divAciertos {
  margin-left: 10px;
  margin-bottom: 10px;
}
@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  20% {
    transform: translate(-3px, 0) rotate(-20deg);
  }
  30% {
    transform: translate(3px, 0) rotate(20deg);
  }
  50% {
    transform: translate(-3px, 0) rotate(-10deg);
  }
  60% {
    transform: translate(3x, 0) rotate(10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
span {
  margin-left: 2px;
  font-size: 30px;
}
.imagenLi {
  height: 20px;
  width: 20px;
  display: none;
}
li:hover .imagenLi {
  display: inline-block;
}
@media only screen and (max-width: 600px) {
  li {
    height: 40px;
    padding-top: 10px;
    font-size: 12px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-bottom: 10px;
    width: 250px;
  }
  span {
    font-size: 20px;
  }
}
</style>
