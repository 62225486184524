import { createApp } from 'vue'
import { ref, onUnmounted } from 'vue'
import App from './App.vue'
import "./css/styles.css"
import "./css/animations.css"
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import { query, where, orderBy, limit } from "firebase/compat/firestore";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const firebaseConfig = {
    apiKey: "AIzaSyAnmhzekC_gaFCBpxKNzIYmrkpLqMdWmJ8",
    authDomain: "whos-that-pokemon-5adf5.firebaseapp.com",
    projectId: "whos-that-pokemon-5adf5",
    storageBucket: "whos-that-pokemon-5adf5.appspot.com",
    messagingSenderId: "673032326560",
    appId: "1:673032326560:web:95da91c774b93d0f4ce8c5",
    measurementId: "G-JS7PQKSD45"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
const usersCollection = db.collection('rankPoke')
    // const db = firebaseApp.collection('users')
    // const usersCollection = db.collection('users')
export const createUser = user => {
    return usersCollection.add(user)
        //return db.add(user)
}
export const loadUsers = () => {
    const users = ref([])
    const close = usersCollection.orderBy("points", "desc").limit(10).onSnapshot(snapshot => {
        users.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            //.orderBy("points", "desc").limit(10)
    })
    onUnmounted(close)
    return users
}
createApp(App).use(VueSweetalert2).mount('#app')