<template>
  <PokemonPage></PokemonPage>
</template>

<script>
import PokemonPage from "./pages/PokemonPage.vue";
import PokemonPicture from "./components/PokemonPicture.vue";
import PokemonOption from "./components/PokemonOption.vue";
import PokemonComodin from "@/components/PokemonComodin.vue";
export default {
  name: "App",
  components: {
    PokemonPage,
    PokemonPicture,
    PokemonOption,
    PokemonComodin,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@media only screen and (max-width: 600px) {
  #app {
    margin-top: 20px;
  }
}
</style>
