<template>
    <div class="row">
        <div class="col-xl-4"></div>
        <div class="col-xl-4 col-12">
            <!-- <form @submit.prevent="onSubmit"> -->
            <form class="m-3" @submit.prevent="onSubmit">
                <div class="form-group">
                    <label>Name</label>
                    <!-- <input v-model="form.name" class="form-control" required /> -->
                    <input
                        v-model="form.name"
                        class="form-control"
                        required
                        maxlength="9"
                        minlength="1"
                        placeholder="name"
                    />
                </div>

                <div class="form-group mt-3">
                    <label>Points</label>
                    <input v-model="msg" class="form-control" type="text" required disabled />
                </div>

                <button
                    type="submit"
                    class="btn btn-success mt-3 buttonQuestionGame"
                >Añadir resultado</button>
            </form>
        </div>
        <div class="col-xl-4"></div>
    </div>
</template>
<script>
import { createUser } from '@/main'
import { reactive } from 'vue'
export default {

    props: {
        points: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const msg = props.points
        const form = reactive({ name: '', points: 0, fecha: Date() })
        form.points = msg


        function addZero(i) {
            if (i < 10) { i = "0" + i }
            return i;
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh = addZero(today.getHours());
        var mmm = addZero(today.getMinutes());
        var ss = addZero(today.getSeconds())
        today = dd + '/' + mm + '/' + yyyy + "  " + hh + ":" + mmm + ":" + ss;
        form.fecha = today
        const onSubmit = async () => {
            await createUser({ ...form })
            form.name = ''
            form.points = 0
            form.fecha = ''
            location.reload();
        }

        return { form, onSubmit, msg }
    }
}
</script>
<style>
/* input:invalid {
    background-color: pink;
} */
</style>